

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import ShowContent from '@shared/components/ShowContent.vue';

@Component({
  components: {
		ShowContent
  }
})
export default class MailConfirmation extends Vue {
  fail = false;
  success = false;

  created() {
    // setTimeout(() => this.success = true, 3000);
    if(!this.$route.query.Token || !this.$route.query.UserId) {
      this.fail = true;
      return;
    }

    this.$store.dispatch('auth/confirmSecondPartnerMail', {
      UserId: this.$route.query.UserId, Token: this.$route.query.Token
    }).then(response => {
      console.log(response);
      this.success = true;
    }).catch(err => {
      console.log(err);
      this.fail = true;
    });
  }
}
