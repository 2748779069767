




























import { Component, Prop, Vue } from 'vue-property-decorator';
import ContentService from '@shared/services/content-service';

@Component
export default class ShowContent extends Vue {
  @Prop({default: ''}) title: string|undefined;
  @Prop() id: string|undefined;
  @Prop() mdid: string|undefined;
  @Prop() vidLink: string|undefined;
  content = null;

  mounted() {
    if(!this.mdid) return;
    ContentService.getHtml(this.mdid as string).then(x => {
      this.content = x.data;
    });
  }

  clearForm() {
    // empty
  }
}
